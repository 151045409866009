<template>
  <div id="settings_content">
    <notify-bar ref="notifyBar" />
    <div class="settings_content_root_container">
      <div
        class="settings_content_root_container__item registration_settings_container"
      >
        <div class="registration_settings_container__item title">
          Регистрация терминалов
        </div>
        <div
          v-if="!terminalRegistrationData.userCanEdit"
          class="registration_settings_container__item forbidden_container"
        >
          <div class="forbidden_container__item forbidden_icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              version="1.0"
              width="40.000000pt"
              height="30.000000pt"
              fill="red"
              viewBox="0 0 512.000000 512.000000"
              preserveAspectRatio="xMidYMid meet"
            >
              <g
                transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                fill="#000000"
                stroke="none"
              >
                <path
                  d="M2370 5110 c-774 -62 -1465 -456 -1909 -1090 -714 -1021 -577 -2432 320 -3295 400 -385 864 -612 1424 -696 198 -30 512 -30 710 0 560 84 1024 311 1424 696 897 863 1034 2274 320 3295 -359 512 -884 874 -1484 1021 -247 60 -567 88 -805 69z m492 -525 c902 -137 1603 -849 1729 -1755 7 -52 13 -174 13 -270 0 -96 -6 -218 -13 -270 -96 -689 -533 -1287 -1156 -1581 -184 -86 -403 -152 -605 -180 -122 -17 -418 -17 -540 0 -917 128 -1633 844 -1761 1761 -17 122 -17 418 0 540 96 689 533 1287 1156 1581 188 88 419 156 606 178 52 6 110 13 129 15 67 8 345 -4 442 -19z"
                  fill="red"
                />
                <path
                  d="M2495 4051 c-16 -10 -38 -27 -48 -39 -45 -50 -46 -64 -47 -714 l0 -618 175 0 175 0 -2 643 -3 644 -29 37 c-16 21 -44 44 -62 52 -47 20 -123 18 -159 -5z"
                  fill="red"
                />
                <path
                  d="M2068 3770 c-20 -11 -47 -34 -60 -52 l-23 -33 -3 -502 -3 -503 171 0 171 0 -3 503 -3 502 -23 33 c-49 68 -149 91 -224 52z"
                  fill="red"
                />
                <path
                  d="M2946 3780 c-37 -12 -85 -56 -102 -97 -11 -26 -14 -128 -14 -518 l0 -485 29 0 c82 0 198 -57 284 -138 l37 -36 0 569 c0 532 -2 573 -19 610 -38 84 -125 122 -215 95z"
                  fill="red"
                />
                <path
                  d="M1654 3471 c-49 -22 -79 -61 -94 -122 -7 -29 -10 -178 -8 -441 l3 -398 69 60 c74 63 146 97 228 107 l48 6 0 341 c0 334 -1 342 -23 380 -26 47 -94 86 -146 86 -20 0 -54 -9 -77 -19z"
                  fill="red"
                />
                <path
                  d="M1803 2586 c-106 -34 -185 -106 -225 -205 -22 -54 -23 -66 -23 -396 0 -380 3 -402 73 -545 52 -106 178 -233 282 -283 134 -65 161 -69 523 -65 487 6 441 -19 963 503 416 415 428 430 428 535 0 143 -131 222 -262 158 -25 -11 -100 -73 -167 -137 -153 -146 -163 -151 -213 -123 -44 24 -173 58 -257 67 -78 8 -187 -9 -252 -41 -105 -50 -188 -170 -200 -288 l-6 -56 -37 0 -37 0 8 58 c16 116 59 201 138 275 95 89 249 137 385 123 60 -7 203 -38 240 -52 17 -7 18 -1 14 107 -3 89 -9 124 -25 162 -32 70 -112 149 -186 183 l-62 29 -530 2 c-419 2 -539 -1 -572 -11z"
                  fill="red"
                />
              </g>
            </svg>
          </div>
          <div class="forbidden_container__item forbidden_title">
            <label>Недостаточно прав</label>
          </div>
        </div>
        <div
          v-else-if="ifTerminalRegistrationDataIsReady"
          class="registration_settings_container__item settings_content_container"
        >
          <div class="settings_content_container__item inline_info_container">
            <div
              class="inline_info_container__item inline_info_container_title"
            >
              Регистрации:
            </div>
            <div
              class="inline_info_container__item inline_info_container_content"
            >
              {{
                terminalRegistrationData.total == -1 ||
                terminalRegistrationData.setValue == -1
                  ? "&#8734;"
                  : terminalRegistrationData.total
              }}
            </div>
            <div
              class="inline_info_container__item inline_info_delta"
              :class="[
                terminalRegistrationChangedDelta > 0 ? 'positive' : 'negative',
              ]"
            >
              {{ terminalRegistrationChangedDelta > 0  && terminalRegistrationData.total !== -1 ? "+" : ""
              }}{{
                terminalRegistrationChangedDelta
                  ? terminalRegistrationChangedDelta
                  : ""
              }}
            </div>
          </div>
          <div class="settings_content_container__item inline_info_container">
            <div
              class="inline_info_container__item inline_info_container_title"
            >
              Доступные регистрации:
            </div>
            <div
              class="inline_info_container__item inline_info_container_title_content"
            >
              {{
                terminalRegistrationData.total == -1 ||
                terminalRegistrationData.setValue == -1
                  ? "&#8734;"
                  : leftNumberTerminalRegisterations
              }}
            </div>
            <div
              class="inline_info_container__item inline_info_delta"
              :class="[
                leftNumberTerminalAllowRegisterationsDelta > 0
                  ? 'positive'
                  : 'negative',
              ]"
            >
              {{ leftNumberTerminalAllowRegisterationsDelta > 0 ? "+" : ""
              }}{{
                leftNumberTerminalAllowRegisterationsDelta
                  ? leftNumberTerminalAllowRegisterationsDelta
                  : ""
              }}
            </div>
          </div>
          <div class="settings_content_container__item set_settings_container">
            <div class="set_settings_container__item set_interface_container">
              <div class="set_interface_container__item number_button">
                <button
                  @click="
                    terminalRegistrationData.setValue !== -1
                      ? addTerminalRegistrationInputValue(-10)
                      : null
                  "
                >
                  <svg
                    width="6"
                    height="10"
                    viewBox="0 0 6 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M0.254229 5.20712L4.33756 9.29045L5.16252 8.4655L1.49167 4.79464L5.16252 1.12379L4.33756 0.298828L0.254229 4.38216C0.0264225 4.60997 0.0264225 4.97931 0.254229 5.20712Z"
                      fill="#a1a3ab"
                    ></path>
                  </svg>
                  <svg
                    width="6"
                    height="10"
                    viewBox="0 0 6 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M0.254229 5.20712L4.33756 9.29045L5.16252 8.4655L1.49167 4.79464L5.16252 1.12379L4.33756 0.298828L0.254229 4.38216C0.0264225 4.60997 0.0264225 4.97931 0.254229 5.20712Z"
                      fill="#a1a3ab"
                    ></path>
                  </svg>
                </button>
              </div>
              <div class="set_interface_container__item number_button">
                <button
                  @click="
                    terminalRegistrationData.setValue !== -1
                      ? addTerminalRegistrationInputValue(-1)
                      : null
                  "
                >
                  <svg
                    width="6"
                    height="10"
                    viewBox="0 0 6 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M0.254229 5.20712L4.33756 9.29045L5.16252 8.4655L1.49167 4.79464L5.16252 1.12379L4.33756 0.298828L0.254229 4.38216C0.0264225 4.60997 0.0264225 4.97931 0.254229 5.20712Z"
                      fill="#a1a3ab"
                    ></path>
                  </svg>
                </button>
              </div>
              <div class="set_interface_container__item limit_value">
                <input
                  type="number"
                  min="0"
                  :value="
                    terminalRegistrationData.setValue !== -1
                      ? terminalRegistrationData.setValue
                      : null
                  "
                  @input="
                    (event) =>
                      (terminalRegistrationData.setValue =
                        Number(event.target.value) > -1
                          ? Number(event.target.value)
                          : null)
                  "
                  :disabled="terminalRegistrationData.setValue === -1"
                  :class="[!ifTerminalRegistrationInputValid ? 'invalid' : '']"
                />
              </div>
              <div class="set_interface_container__item number_button">
                <button
                  @click="
                    terminalRegistrationData.setValue !== -1
                      ? addTerminalRegistrationInputValue(1)
                      : null
                  "
                >
                  <svg
                    transform="scale(-1,1)"
                    width="6"
                    height="10"
                    viewBox="0 0 6 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M0.254229 5.20712L4.33756 9.29045L5.16252 8.4655L1.49167 4.79464L5.16252 1.12379L4.33756 0.298828L0.254229 4.38216C0.0264225 4.60997 0.0264225 4.97931 0.254229 5.20712Z"
                      fill="#a1a3ab"
                    ></path>
                  </svg>
                </button>
              </div>
              <div class="set_interface_container__item number_button">
                <button
                  @click="
                    terminalRegistrationData.setValue !== -1
                      ? addTerminalRegistrationInputValue(10)
                      : null
                  "
                >
                  <svg
                    transform="scale(-1,1)"
                    width="6"
                    height="10"
                    viewBox="0 0 6 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M0.254229 5.20712L4.33756 9.29045L5.16252 8.4655L1.49167 4.79464L5.16252 1.12379L4.33756 0.298828L0.254229 4.38216C0.0264225 4.60997 0.0264225 4.97931 0.254229 5.20712Z"
                      fill="#a1a3ab"
                    ></path>
                  </svg>
                  <svg
                    transform="scale(-1,1)"
                    width="6"
                    height="10"
                    viewBox="0 0 6 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M0.254229 5.20712L4.33756 9.29045L5.16252 8.4655L1.49167 4.79464L5.16252 1.12379L4.33756 0.298828L0.254229 4.38216C0.0264225 4.60997 0.0264225 4.97931 0.254229 5.20712Z"
                      fill="#a1a3ab"
                    ></path>
                  </svg>
                </button>
              </div>
              <div class="set_interface_container__item no_limit_button">
                <div class="no_limit_button__item online_switcher">
                  <button
                    @click="onTerminalRegistrationSwitcherClick"
                    style="all: unset"
                  >
                    <div
                      v-if="this.terminalRegistrationData.setValue !== -1"
                      class="switcher_off"
                    >
                      <svg
                        width="40"
                        height="30"
                        viewBox="0 0 50 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M15.3276 4.05053C16.3512 3.9957 17.3735 4.00008 18.3971 4.00008C18.4041 4.00008 23.5835 4.00008 23.5835 4.00008C24.6271 4.00008 25.6494 3.9957 26.6724 4.05053C27.6024 4.09989 28.5082 4.20518 29.41 4.44044C31.3082 4.93508 32.9659 5.96824 34.1641 7.43297C35.3553 8.88838 36 10.6701 36 12.4995C36 14.3311 35.3553 16.1117 34.1641 17.5671C32.9659 19.0313 31.3082 20.065 29.41 20.5596C28.5082 20.7949 27.6024 20.8996 26.6724 20.9495C25.6494 21.0044 24.6271 20.9995 23.6035 20.9995C23.5965 20.9995 18.4159 21 18.4159 21C17.3735 20.9995 16.3512 21.0044 15.3276 20.9495C14.3982 20.8996 13.4924 20.7949 12.5906 20.5596C10.6924 20.065 9.03471 19.0313 7.83647 17.5671C6.64529 16.1117 6 14.3311 6 12.5C6 10.6701 6.64529 8.88838 7.83647 7.43297C9.03471 5.96824 10.6924 4.93508 12.5906 4.44044C13.4924 4.20518 14.3982 4.09989 15.3276 4.05053Z"
                          fill="#C9C9C9"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M14.5 19C18.0899 19 21 16.0899 21 12.5C21 8.91015 18.0899 6 14.5 6C10.9101 6 8 8.91015 8 12.5C8 16.0899 10.9101 19 14.5 19Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                    <div v-else class="switcher_on">
                      <svg
                        width="40"
                        height="30"
                        viewBox="0 0 50 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M15.3276 4.05053C16.3512 3.9957 17.3735 4.00008 18.3971 4.00008C18.4041 4.00008 23.5835 4.00008 23.5835 4.00008C24.6271 4.00008 25.6494 3.9957 26.6724 4.05053C27.6024 4.09989 28.5082 4.20518 29.41 4.44044C31.3082 4.93508 32.9659 5.96824 34.1641 7.43297C35.3553 8.88838 36 10.6701 36 12.4995C36 14.3311 35.3553 16.1117 34.1641 17.5671C32.9659 19.0313 31.3082 20.065 29.41 20.5596C28.5082 20.7949 27.6024 20.8996 26.6724 20.9495C25.6494 21.0044 24.6271 20.9995 23.6035 20.9995C23.5965 20.9995 18.4159 21 18.4159 21C17.3735 20.9995 16.3512 21.0044 15.3276 20.9495C14.3982 20.8996 13.4924 20.7949 12.5906 20.5596C10.6924 20.065 9.03471 19.0313 7.83647 17.5671C6.64529 16.1117 6 14.3311 6 12.5C6 10.6701 6.64529 8.88838 7.83647 7.43297C9.03471 5.96824 10.6924 4.93508 12.5906 4.44044C13.4924 4.20518 14.3982 4.09989 15.3276 4.05053Z"
                          fill="#5BC367"
                        />
                        <path
                          fill-rule="evenodd"
                          transform="translate(13, 0)"
                          transform-origin="center"
                          clip-rule="evenodd"
                          d="M14.5 19C18.0899 19 21 16.0899 21 12.5C21 8.91015 18.0899 6 14.5 6C10.9101 6 8 8.91015 8 12.5C8 16.0899 10.9101 19 14.5 19Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                  </button>
                </div>
                <div class="no_limit_button no_limit_button_title">
                  Безлимитно
                </div>
              </div>
            </div>
          </div>
          <div class="settings_content_container__item apply_button_container">
            <button
              @click="
                allowApplyTerminalRegistrationSettings
                  ? onApplyTerminalRegistrationSettings()
                  : null
              "
              :class="
                allowApplyTerminalRegistrationSettings
                  ? 'blue_button'
                  : 'gray_button'
              "
            >
              Установить
            </button>
          </div>
        </div>
        <div v-else class="registration_settings_container__item">
          <span class="line_loader"></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NotifyBar from "../components/NotifyBar.vue";

export default {
  components: {
    "notify-bar": NotifyBar,
  },
  data() {
    return {
      terminalRegistrationData: {
        used: null,
        total: null,
        setValue: null,
        userCanEdit: true,
      },
    };
  },
  methods: {
    // Terminal registraion part

    // apply settings
    onApplyTerminalRegistrationSettings() {
      this.$refs.notifyBar.addWaitMsg({
        overWriteMsg: "Установка конфигурации",
      });
      this.$http
        .post(`${this.$backEndUrl}/v1/proxy/api`, {
          url: `/v2/setting/boot?approvals=${this.terminalRegistrationData.setValue}`,
          method: "PUT",
          payload: {},
          headers: {},
        })
        .then((response) => {
          if (response.data.statusCode !== 200) {
            console.log(
              "Error to put (/v2/settings/boot)",
              response.data.payload
            );
            this.$refs.notifyBar.addErrorMsg({
              overWriteMsg: `Ошибка установка конфигурации`,
            });
            return;
          }
          this.$refs.notifyBar.addOKMsg({
            overWriteMsg: "Конфигурация установлена",
          });
          this.getTerminalRegistraionData();
        })
        .catch((error) => {
          console.log("Error to put (/v2/settings/boot)", error);
          if (error.request.status === 403) {
            this.$refs.notifyBar.addErrorMsg({
              overWriteMsg: "Недостаточно прав",
            });
            return;
          }
          this.$refs.notifyBar.addErrorMsg({
            overWriteMsg: "Ошибка установка конфигурации",
          });
        });
    },

    // get terminal data from API
    getTerminalRegistraionData() {
      this.$http
        .post(`${this.$backEndUrl}/v1/proxy/api`, {
          url: `/v2/setting/boot`,
          method: "GET",
          payload: {},
          headers: {},
        })
        .then((res) => {
          console.log(
            "Response from proxy api obtained (/v2/setting/boot)",
            res
          );
          if (res.status === 200) {
            this.terminalRegistrationData.total =
              res.data.payload.approvals.total;
            this.terminalRegistrationData.used =
              res.data.payload.approvals.used;
            this.terminalRegistrationData.setValue =
              res.data.payload.approvals.total;

            this.terminalRegistrationData.userCanEdit = true;
          }
        })
        .catch((error) => {
          console.log("Error to get (/v2/setting/boot)", error);
          if (error.request.status === 403) {
            this.terminalRegistrationData.userCanEdit = false;
            return;
          }
        });
    },

    // actions on switch button click
    onTerminalRegistrationSwitcherClick() {
      if (this.terminalRegistrationData.setValue === -1) {
        this.terminalRegistrationData.setValue =
          this.terminalRegistrationData.total !== -1
            ? this.terminalRegistrationData.total
            : this.terminalRegistrationData.used;
      } else {
        this.terminalRegistrationData.setValue = -1;
      }
    },

    // add value to input
    addTerminalRegistrationInputValue(value) {
      if (
        // more than 0
        this.terminalRegistrationData.setValue + value > -1 &&
        // not less than used
        this.terminalRegistrationData.setValue + value >=
          this.terminalRegistrationData.used
      ) {
        this.terminalRegistrationData.setValue += value;
        return;
      }
      this.terminalRegistrationData.setValue =
        this.terminalRegistrationData.used;
    },
  },
  created() {
    this.getTerminalRegistraionData();
  },
  computed: {
    leftNumberTerminalRegisterations() {
      return (
        this.terminalRegistrationData.total - this.terminalRegistrationData.used
      );
    },
    leftNumberTerminalAllowRegisterationsDelta() {
      if (
        this.terminalRegistrationData.setValue !== -1 &&
        this.terminalRegistrationData.setValue !==
          this.terminalRegistrationData.total
      ) {

        return this.terminalRegistrationData.setValue -
          this.terminalRegistrationData.used -
          (this.terminalRegistrationData.total ===
          -1
          ? 0
          : this.terminalRegistrationData.total - this.terminalRegistrationData.used);
      }
      console.log("here1111");
      return null;
    },
    terminalRegistrationChangedDelta() {
      if (
        this.terminalRegistrationData.setValue !== -1 &&
        this.terminalRegistrationData.setValue !==
          this.terminalRegistrationData.total &&
        this.terminalRegistrationData.setValue
      ) {
        return (
          this.terminalRegistrationData.setValue -
          (this.terminalRegistrationData.total === -1
            ? 0
            : this.terminalRegistrationData.total)
        );
      }
      return null;
    },
    ifTerminalRegistrationDataIsReady() {
      return (
        this.terminalRegistrationData.used !== null &&
        this.terminalRegistrationData.total !== null
      );
    },
    allowApplyTerminalRegistrationSettings() {
      // nothing changed
      if (
        this.terminalRegistrationData.setValue ===
        this.terminalRegistrationData.total
      ) {
        return false;
      }

      // change to limit (more than -1)
      if (!this.ifTerminalRegistrationInputValid) {
        return false;
      }

      return true;
    },
    ifTerminalRegistrationInputValid() {
      return !(
        this.terminalRegistrationData.setValue !== -1 &&
        this.terminalRegistrationData.setValue <
          this.terminalRegistrationData.used
      );
    },
  },
};
</script>

<style lang="less">
@import "../assets/styles/buttons.less";
@import "../assets/styles/colors.less";
@import "../assets/styles/animations.less";

#settings_content {
  .settings_content_root_container {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    .forbidden_container {
      display: flex;
      align-items: center;
      .forbidden_icon {
        margin-left: -5px;
        margin-right: 10px;
      }
      .forbidden_title {
        font-size: 1.2em;
      }
    }
    .registration_settings_container {
      .registration_settings_container__item {
        &.title {
          font-family: Roboto;
          font-size: 20px;
          font-weight: 500;
          line-height: 23px;
          letter-spacing: 0em;
          text-align: left;
          margin-bottom: 20px;
        }

        &.settings_content_container {
          display: flex;
          flex-direction: column;
          .settings_content_container__item {
            margin-bottom: 10px;
          }
          .inline_info_container {
            display: flex;
            width: 100%;

            .inline_info_container_title {
              margin-right: 10px;
            }
            .inline_info_delta {
              margin-left: 10px;
              &.positive {
                color: @ok-color;
              }
              &.negative {
                color: @invalid-color;
              }
            }
          }
          .set_settings_container {
            display: flex;
            flex-direction: column;
            .set_interface_container {
              display: flex;
              align-items: center;
              .limit_value {
                margin-right: 10px;
                input {
                  width: 80px;
                  border: 1px solid #c9c9c9;
                  border-radius: 5px;
                  padding: 3px 5px;
                  &::-webkit-outer-spin-button,
                  &::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                  }
                  &.invalid {
                    color: red;
                  }
                }
              }

              .number_button {
                margin-right: 10px;
                button {
                  all: unset;
                  cursor: pointer;
                }
              }
              .no_limit_button {
                display: flex;
                align-items: center;
                margin-left: 20px;
                .online_switcher {
                  cursor: pointer;
                }
                .no_limit_button_title {
                  margin-left: 5px;
                  font-size: 15px;
                }
              }
            }
          }
          .apply_button_container {
          }
        }
      }
    }
  }
}
</style>
